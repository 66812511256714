import * as React from "react"

import PropTypes from "prop-types"
import { SmartImageWithoutPlaceholder } from "../utils/imageHandler"

const Seo = ({ author, description, title, image, settings }) => {
  const parsedSettings = settings
  const metaTitle = title ? `${title}` : parsedSettings.site_name
  const metaDescription = description || parsedSettings.site_description
  const metaAuthor = author || parsedSettings?.site_default_author
  const defaultTitle = parsedSettings?.name
  const metaImage =
    SmartImageWithoutPlaceholder(image?.filename, "1200x627") ||
    SmartImageWithoutPlaceholder(
      parsedSettings?.site_image?.filename,
      "1200x627"
    )

  return (
    <>
      {defaultTitle ? (
        <title>
          {metaTitle} / {defaultTitle}
        </title>
      ) : (
        <title>{metaTitle}</title>
      )}
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={metaAuthor} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </>
  )
}

export default Seo

Seo.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
